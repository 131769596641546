@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-black: 0 0 0;
  }
}

@layer components {
  .card {
    @apply bg-black bg-opacity-2;
  }

  .card:hover {
    @apply bg-black bg-opacity-5;
  }

  .button:hover {
    box-shadow: 0 0 0 2px theme(colors.primary);
  }

  .status-light {
    box-shadow: -7px 8px 14px -2px rgb(0, 0, 0, 0.65);
  }

  .progress-bar-battery-level {
    box-shadow: 0 7px 36px 0 rgb(0, 0, 0, 0.6);
  }

  .large-dial {
    filter: drop-shadow(-15px 20px 20px rgba(0, 0, 0, 0.68));
  }

  .landing-page-tile {
    box-shadow: 0 21px 64px 0 rgb(0, 0, 0, 0.5);
  }

  .tooltip {
    box-shadow: 0 64px 74px 0 rgb(0, 0, 0, 0.65);
  }

  .login-panel {
    /* @apply bg-gradient-to-b from-black-900 bg-opacity-94 to-black-900/[0.82] text-white; */
    @apply bg-gradient-to-b from-black-900/[0.752] to-black-900/[0.656] text-white;
    box-shadow: 0 34px 34px 0 rgb(0, 0, 0, 0.25);
  }

  .landing-page {
    box-shadow: 0 34px 34px 0 rgb(0, 0, 0, 0.25);
  }

  .large-mower-light-source {
    @apply bg-black !important;
    /* TODO: 264 */
    filter: blur(30px);
  }

  .large-mower-shadow {
    @apply bg-black !important;
    /* TODO: 324 */
    filter: blur(50px);
  }
}

@layer utilities {
  .overflow-y-overlay {
    overflow-y: overlay;
  }
}

/* Font faces */
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

body {
  overflow-y: hidden;
}

* {
  font-family: 'Inter';

  /* For Firefox */
  /* scrollbar thumb, scrollbar track */
  scrollbar-color: #dddddd #000000;
  scrollbar-width: thin;
}

/* Global Scrollbar */
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #000000;
}

*::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  background-clip: padding-box;
  border-radius: 10px;
  background-color: #dddddd;
}

/* Global Outline */
*:focus {
  outline: 2px solid #ffffff;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px #000000 inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #dddddd !important;
}

/* Override input[type=password] for Inter Font */
/* https://github.com/rsms/inter/issues/112 */
@supports (-moz-appearance: none) {
  input[type='password']:not(:placeholder-shown) {
    font-family: monospace;
    font-size: 12px;
    letter-spacing: 1px;
  }
}

.twist-arrow-groups{
  transform: rotate(90deg);
  margin-right: 70px;
}

iframe#webpack-dev-server-client-overlay{display:none!important}